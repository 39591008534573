
.sponsored-projects {
  table {
    tbody {
      tr {
        td {
          // THIS IS IMPORTANT. This table does not drill-down!
          cursor: pointer;
        }
      }
    }
  }
}
