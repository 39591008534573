

.showbox {
  z-index:9999;
  width : 100%;
  height : 60px;
}

// end demo-specific
.spinner {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  background-color: #4e2a84;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

