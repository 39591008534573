// Layout

html {
  position: relative;
  min-height: 100%;
}

body {
	font-family: Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  color: #444;
  margin-top: 100px; //Header Height
  margin-bottom: 120px; //Footer Height
  padding: 0;
  background-color: $ultralight-grey;
  @media all and (max-width: 768px){
    margin-top: 0;
    margin-bottom: 575px;
  }
}

#content{
  padding: 10px 15px;
  @media all and (max-width: $mobile_max){
    padding: 10px 0px;
  }
}

.container{
  max-width: 1600px;
  @media (min-width: 768px){
    width: 100%;
  }
}

.section{
  padding: 10px 0px;
}

button.page-top{
  margin-bottom: 10px;
}



// Metadata Block

.back-to-portfolio{
  margin-bottom: 10px;
}

.metadata-block{
  position: relative;
  width: 100%;
  border: 1px solid $light-grey;
  border-bottom: 2px solid $light-grey;
  padding: 0px 10px;
  background-color: white;
  @include clearfix;

  h1{
    margin: 10px 0px;
  }

  .metadata-flex-wrap{
    display: flex;
    justify-content: space-between;
    div{
      flex-grow: 1;
    }
    .download-actions{
      flex-grow: 0;
    }
  }

  .usercard-wrapper{
    margin: 10px 0px;
    float: left;

    .usercard {
      font-family: $AkkuratProRegular;
      @include clearfix;
      .user-profile-picture{
        display: inline-block;
        vertical-align: top;
        max-width: 60px;
        margin-right: 10px;
      }

      .usercard-info{
        display: inline-block;
        color: $rich-black-50;
        .user-name-email{
          float: left;
          .user-name{
            font-size: 26px;
            line-height: 1.2em;
            margin: 0;
            margin-top: 4px;
          }
          .email{
            margin: 0;
          }
          .user-position{
            display: inline-block;
            padding-top: 10px;
          }
        }
      }
    }
  }

  .download-actions{
    margin: 10px auto;
    padding: 0px;
    position: relative;
    float: right;
    display: flex;
    flex-direction: column;

    button{
      display: block;
      margin: 0px;
      margin-bottom: 10px;
    }
  }

  .tabs{
    clear: both;
    border-top: 1px solid $light-grey;
    padding: 0;
  	text-align: center;
    line-height: 0;
    .nav-pills{
      margin: 10px 0px;
    }
  }
}



@media all and (max-width: $mobile_max){
  .metadata-block{
    border-left: 0;
    border-right: 0;
    .metadata-flex-wrap{
      display: block;
      justify-content: space-between;
      div{
        // flex-grow: 1;
      }
      .download-actions{
        // flex-grow: 0;
      }
    }
    .usercard-wrapper{
      float: none;
      margin-bottom: 20px;
      .usercard{
        .user-profile-picture{
          margin: auto;
          display: block;
        }
        .usercard-info{
          margin: 0;
          display: block;
          text-align: center;
          .user-name-email{
            float: none;
            .email{
              small{
                display: none;
              }
            }
            .user-position{
              display: none;
            }
          }
        }
      }
    }

    .download-actions{
      float: none;
      padding: 0;
      button{
        width: 100%;
      }
    }

    .tabs{
      .nav-pills{
        margin: 0;
        width: 100%;
        li{
          width: 100%;
          float: none;
          margin-left: 0 !important;
          margin-right: 0 !important;
          margin-top: 10px;
          &:last-of-type{
            margin-bottom: 10px;
          }
          a.tab{
            margin: 0 !important;
            width: 100%;
            float: none;
          }
        }
      }
    }
  }
}



// Card Styles
.card {
  margin: 15px 0px;
  width: 100%;
  cursor: pointer;
  background: white;
  border: 0px;
  border: 1px solid $nu-purple;
  @include transitionAll;
  ul{
    padding-left: 0px;
    li{
      margin-top: 5px;
      padding: 0px 5px;
      display: flex;
      flex-wrap: nowrap;
      flex-flow: row;
      justify-content: flex-start;
      position: relative;
      .key {
        flex-basis: auto;
        flex-shrink: 1;
        flex-grow: .2;
        width: 15%;
        margin-right: 15px;
      }
      .value{
        text-align: left;
        flex-basis: auto;
        flex-shrink: 1;
        width: 10%;
        flex-grow: 1;
      }
      &:first-of-type{
        margin-top: 0;
        padding: 5px;
        background-color: $nu-purple;
        .key{
          display: none;
        }
        .value{
          color: white;
        }
      }
    }
  }
  .projection_options{
    padding: 0px;
    margin: 5px;
    margin-top: 10px;
    @media all and (max-width: $mobile_max){
      margin: 5px;
      button{
        display: block;
        width: 100%;
        margin: 5px 0px;
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
  &.not_avail{
    border: none;
  }
}






// Project Metadata
.project-metadata,
.sponsored-project-metadata,
.non-sponsored-project-metadata{
  ul{
    list-style-type: none;
    padding: 0;
    li{
      margin-top: 5px;
      @media all and (min-width: 900px){
        display: flex;
        flex-wrap: nowrap;
        flex-flow: row;
        justify-content: flex-start;
        position: relative;
        .key {
          flex-basis: auto;
          flex-shrink: 1;
          flex-grow: .2;
          width: 10%;
          margin-right: 15px;
        }
        .value{
          text-align: left;
          flex-basis: auto;
          flex-shrink: 1;
          width: 10%;
          flex-grow: 1;
        }
      }
    }
  }
  .special-currency{
    position: relative;
    text-align:left;
    width: 8em;
    &::before {
      content : "$";
      position: relative;
      left : 0px;
    }
  }
}


// Web Forms
.web-form{
  .field{
    margin-bottom: 10px;
    label{
      display: block;
      font-family: $AkkuratProBold;
    }
    input,
    textarea{
      border: 1px solid $rich-black-20;
      &:focus{
        outline: none;
      }
    }
    textarea{
      display: block;
      width: 100%;
      max-width: 100%;
      min-height: 100px;
    }
  }
}
