@mixin transitionAll ($duration: .2s) {
	-moz-transition: all $duration ease;
	-webkit-transition: all $duration ease;
	-o-transition: all $duration ease;
	transition: all $duration ease;
}

@mixin transition ($property: all, $duration: .2s){
	-moz-transition: $property $duration ease;
	-webkit-transition: $property $duration ease;
	-o-transition: $property $duration ease;
	transition: $property $duration ease;
}

@mixin clearfix () {
  &:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

@mixin no-highlight{
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none;   /* Chrome/Safari/Opera */
	-khtml-user-select: none;    /* Konqueror */
	-moz-user-select: none;      /* Firefox */
	-ms-user-select: none;
	user-select: none;
}

.no-highlight{
	@include no-highlight;
}

@mixin box-shadow{
	-webkit-box-shadow: 0 -4px 6px rgba(0, 0, 0, .33);
	-moz-box-shadow: 0 -4px 6px rgba(0, 0, 0, .33);
	box-shadow: 0 -4px 6px rgba(0, 0, 0, .33);
}

@mixin backdrop ($col1, $col2){
	background: -moz-linear-gradient(top, $col1 0%, $col2 10%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, $col1 0%, $col2 10%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, $col1 0%, $col2 10%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}

@mixin in-shadow{

-webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
-moz-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
}


@mixin no-shadow{
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}


@-webkit-keyframes sk-scaleout-x {
  0% {
    -webkit-transform: scalex(0);
    -webkit-transform-origin: 0px;
  }
  100% {
    -webkit-transform: scalex(1.0);
    -webkit-transform-origin: 0px;
    opacity: 0;
  }
}

@keyframes sk-scaleout-x {
  0% {
    transform: scalex(0);
    transform-origin: 0px;
  }
	100% {
    transform: scalex(1.0);
    transform-origin: 0px;
    opacity: 0;
  }
}

@keyframes sk-rotate {
  0% {
		transform: rotate(0deg);
  }
	100%{
		transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-rotate {
  0% {
		-ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
	100% {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
  }
}


@-webkit-keyframes sk-scaleout {
  0% {
		-webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
	100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}
