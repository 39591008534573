.expand-enter-active {
  -webkit-transition: all 0.3 ease;
  -moz-transition: all 0.3 ease;
  -o-transition: all 0.3 ease;
  transition: all 0.3 ease;
  opacity: 1;
}
.expand-enter, .expand-leave-active {
  opacity: 0;
}

#skrollr-body{
  left: 0px;
  width: 100%;
  padding: 0;
  background-color: $ultralight-grey;
  z-index:999;
  opacity: 1;
  position:fixed;
  top:98px;
  top: -200px;
  @include transition(top, .5s);
  .table_head_wrap{
    border: 1px solid $light-grey;
    background-color: white;
    border-bottom: 0px;
    padding: 10px;
    padding-bottom: 0;
  }
}


// Table Wrap
.table_wrap{
  margin-top: 20px;
  padding: 10px;
  border: 1px solid $light-grey;
  border-bottom: 2px solid $light-grey;
  background-color: white;
}

// Table Menu Styling
.table_menu_flex{
  position: relative;
  @include clearfix;
  margin-bottom: 10px;
  display: flex;
  .flex_child{
    flex-grow: 1;
  }
  .table_nav{
    order: 1;
    flex-grow: 0;
  }
  .table_title{
    order: 2;
    flex-shrink: 0;
    h2{
      line-height: 39px;
      margin: 0;
    }
  }
  .table_switcher{
    order: 3;
    flex-shrink: 0;
    .rd-small-button{
      margin-right: 2px;
      &:last-of-type{
        margin: 0px;
      }
    }
  }
  .table_buttons {
    order: 4;
    flex-shrink: 0;
  }
  .table_projection_menu{
    order: 4;
    flex-shrink: 0;
  }
  .table_datepicker,
  .table_periodpicker{
    order: 4;
    @include clearfix;
  }
  .table_search{
    order: 5;
    position: relative;
    text-align: left;
    flex-grow: 1;
    padding: 8px 0px 6px 0px;
    border: 1px solid $light-grey;
    background-color: $ultralight-grey;
    max-width: 350px;
    @media all and (max-width: 750px){
      max-width: 200px;
    }
    @include transitionAll;
    &:hover{
      @include transitionAll;
      background-color: white;
    }
    input{
      width: 100%;
      color: $nu-purple;
      font-family: $AkkuratProRegular;
      border: 0px;
      margin: 0px;
      text-indent: 5px;
      padding-right: 30px;
      background-color: transparent;
      text-overflow: ellipsis;
      &::-webkit-input-placeholder {
        color: $rich-black-50;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: $nu-purple-30;
      }
      &::-moz-placeholder {  /* Firefox 19+ */
        color: $nu-purple-30;
      }
      &:-ms-input-placeholder {
        color: $nu-purple-30;
      }
      &:focus{
        outline: none;
        &::-webkit-input-placeholder {
          color: $ultralight-grey;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $ultralight-grey;
        }
        &::-moz-placeholder {  /* Firefox 19+ */
          color: $ultralight-grey;
        }
        &:-ms-input-placeholder {
          color: $ultralight-grey;
        }
      }
    }
    i{
      position: absolute;
      right: 10px;
      top: 12px;
      color: $rich-black-50;
    }
  }
}

// Table Content
.table_content{
  position:relative;
  width:100%;
  clear: both;
  .loading-time {
    margin-top: 20px;
    float: right;
    color: $rich-black-50;
  }
  .error{
    background-color: $nu-purple-10;
    padding: 20px 10px;
    margin: 0px;
    color: $nu-purple-120;
    text-align: center;
    border: 2px solid $nu-purple-120;
    border-top: none;
  }
}


// Table Responsive
@media all and (max-width: $mobile_max){
  .table_wrap{
    padding: 0px;
    border-left: 0;
    border-right: 0;
    background-color: white;
    margin-bottom: 15px;
    .table_menu_flex{
      display: block;
      border-bottom: 1px solid $light-grey;
      padding: 0px;
      margin: 10px;
      @include clearfix;
      .flex_child{
        @include clearfix;
        order: 1;
        clear: both;
        float: none;
        width: 100%;
        display: block;
        button{
          display: block;
          width: 100%;
          margin: 5px 0px;
        }
      }
      .table_title{
        height: auto;
        h2{
          padding: 10px 0px 20px 0px;
          line-height: 1em;
        }
      }
      .table_projection_menu{
        margin-top: 5px;
      }
      .table_search{
        margin: 10px 0px;
        max-width: none;
        width: 100%;
        input{
          height: 22px;
          width: 100%;
        }
      }
    }

    .table-vertical{
      padding: 0px 10px;
    }

    .edit-mode {
      position: absolute;
      top: 0px;
      left: 0px;
      width : 100%;
      height : 1em;
      input {
        width : 100%;
      }
    }
  }

  .table_menu_flex{
    justify-content: flex-start;
    .table_search{
      text-align: left;
    }
  }

}//End (max-width: $mobile_max)


// General Table Styling
table{
  border: 2px solid $nu-purple;
  background-color: #fff;
}

tr{
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  flex-flow: row;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;
  th,
  td{
    flex: 1 0 auto;
    text-overflow: ellipsis;
    line-height: 1.3em;
    position: relative;
    display: inline-block;
    padding: 5px;
  }
  td{
    font: 15px $AkkuratProRegular;
    color: $rich-black-80;
    background-color: white;
    padding: 5px;
    height: auto;
    overflow: hidden;
    border-right: 1px solid #f0f0f0;
    &:last-of-type{
      border: none;
    }
    @media all and (max-width: 1000px){
      font-size: 12px;
    }
  }
}

thead{
  background-color: $nu-purple-120;
  tr{
    th {
      background-color: $nu-purple;
      font: 14px $AkkuratLight;
      line-height: 25px;
      line-height: 1.2em;
      padding: 5px 12px 5px 5px;
      color: white;
      border-right: 1px solid $nu-purple-60;
      border-bottom: 2px solid $nu-purple;
      cursor: pointer;
      box-sizing: border-box;
      @include no-highlight;
      @media all and (max-width: 1000px){
        font-size: 12px;
      }
      &:hover{
        background-color: $nu-purple-60;
      }
      &:last-of-type{
        border-right: none;
      }
      .arrow {
        position: absolute;
        top: 14px;
        right: 3px;
        display: inline-block;
        width: 0;
        height: 0;
      }
      .arrow.asc {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 4px solid #fff;
      }
      .arrow.dsc {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #fff;
      }
    }
  }
}


tbody{
  background-color: $medium-grey;
  background-color: white;
  tr{
    cursor : pointer;
    border-top: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
    td.clickable{
      @include transitionAll(.2s);
      background-image: url("#{$path-images}/RD_Icons_goto.svg");
      background-repeat: no-repeat;
      background-position: center right -30px;
      background-size: 20px;
      &:hover{
        background-color: $nu-purple-30;
        padding-right: 30px;
        @include transitionAll(.2s);
        background-position: center right 10px;
      }
    }
    &:hover{
      td{
        background-color : $nu-purple-10;
      }
    }
    &.NOT_FUNDED{
      td{
        background-color: lighten($rich-black-10, 10%);
        color: darken($rich-black-10, 10%);
      }
    }
  }
}



// User Image
.profile_style{
  min-width: 30px;
  width: 5%;
  padding: 5px;
  flex-grow: 0;
  flex-basis: auto;
  span{
    display: none !important;
  }
  &.wildcat{
    .user-thumb{
      background-size: 25px !important;
    }
  }
}


th.profile_style{
  &:before{
    display: inline-block;
    font-family: $Ionicons;
    content: '\f213';
    color: $nu-purple-60;
    text-align: center;
    width: 100%;
  }
  &:hover{
    background-color: $nu-purple;
  }
}

.user-thumb{
  text-align : center;
  border-radius : 50%;
  margin-left: auto;
  margin-right: auto;
  background-color: $nu-purple-120;
  color: white;
  font-family: $AkkuratLight;
  line-height: 25px;
  font-size: 12px;
  height: 25px;
  width: 25px;
  padding: 0;
  letter-spacing: -.75px;
  text-indent: -.5px;
  @include transitionAll ();
}
tr:hover{
  .user-thumb{
    -ms-transform: scale(1.2); /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari */
    transform: scale(1.2);
    @include transitionAll ();
  }
}


// Projection Tool
.fin-overview {
  td._PROJECTED {
    padding: 0px;
    font-family: $AkkuratProBold;
    background-color: $ultralight-grey;

    &:before{ //Hide Default Dollar Sign
      display: none !important;
    }

    &.zero{
      background: rgba(0,255,0,0.1);
      &:before{
        color: darkgreen;
      }
      .non-edit-mode{
        color: darkgreen;
      }
    }

    &.no-currency{ //When Clear
      background-color: lightgreen;
    }

    .dollar{
      @include transitionAll;
      position: relative;
      width: 25px;
      left: 0px;
      margin-right: 15px;
      pointer-events: none;
    }

    .ion-edit,
    .ion-checkmark-circled{
      position: absolute;
      right: 4px;
      top: 5px;
      width: 25px;
      @include transitionAll;
    }

    .non-edit-mode{
      padding: 5px;
      padding-right: 25px;
      font-family: $AkkuratProBold;
      color: $rich-black-80;
      &:hover{
        .ion-edit{
          @include transitionAll;
          -ms-transform: rotate(14deg); /* IE 9 */
          -webkit-transform: rotate(14deg); /* Chrome, Safari, Opera */
          transform: rotate(14deg);
        }
      }
    }

    .edit-mode{
      padding: 0px;
      color: darkgreen;
      input{
        position: relative;
        display: block;
        width: 100%;
        padding: 5px;
        padding-right: 25px;
        background: transparent;
        line-height: 1;
        &:focus{
          outline: none;
        }
      }
      .dollar{
        @include transitionAll;
        position: absolute;;
        width: 25px;
        left: 0px;
        top: 5px;
      }
    }
  }
}

///////////////////////////////////////////////////////
/////////////////////// Mixins ////////////////////////
/////////////////vvvvvvvvvvvvvvvv//////////////////////


._PROJECTED{
  .rd-btn{
    display: none;
  }
}

@mixin flex-col-small{
  flex-basis: 10%;
  flex-shrink: 1;
  // background-color: red;
}

@mixin flex-col-med{
  flex-basis: 15%;
  // background-color: blue;
}

@mixin flex-col-large{
  flex-basis: 20%;
  // background-color: orange;
}

@mixin flex-col-exlarge{
  flex-basis: 30%;
  // background-color: yellow;
}

@mixin flex-col-currency{
  // background-color: red;
  flex-basis: 15%;
  &:not(th){
    text-align: right;
    &:before{
      margin-right: 10px;
      content: '$';
    }
    div{
      float: right;
      text-align: right;
      .ion-edit{
        margin-left: 5px;
      }
      &.edit-mode{
        color: blue;
        @include transitionAll;
      }
    }
    &.negative{
      color : red;
    }
    td.zero {
      opacity : 0.5;
    }
  }
}

@mixin flex-col-percent{
  // background-color: red;
  flex-basis: 10%;
  &:not(th){
    text-align: right;
    &:after{
      margin-left: 10px;
      content: '%';
    }
    &.negative{
      color : red;
    }
    td.zero {
      opacity : 0.5;
    }
  }
}


///////////////////////////////////////////////////////
//////////////// Implement Mixins /////////////////////
/////////////////vvvvvvvvvvvvvvvv//////////////////////

.currency,
.Actual,
.Encumbered,
.Budget,
._SUM,
.Balance{
  @include flex-col-currency;
}

.percent{
  @include flex-col-percent;
}

.YYYYMM,
.Favorites,
.Actions,
.profile_style,
.Date,
.TransactionID,
.ProjectID,
.PrimaryRole,
.ProjectEndDate,
.ProjectRequestStartDate,
.ProjectRequestEndDate,
.DateSubmitted,
.CurrentProposalStatus,
.ResearcherNetID,
.TransactionSource,
._TRANSACTION_SOURCE_WITH_METADATA,
._SUM,
.TransactionType,
.Invoice,
.DepositDate,
.PostedDate,
.DateSent,
.PaymentID,
.VendorID,
.PONumber,
.POStatus,
.ReqID,
.InvoiceNumber,
.VoucherID,
.AccountCode,
.AccountingDate,
.InvoiceDate,
.BudgetPeriod,
.ProposalNumber,
.InstitutionNumber,
.BUDGET_PERIOD,
// .Sponsor,
.protocol_id,
.protocol_status,
.expiration_date,
.last_approval_date,
.CheckDate,
.Account,
.EntryType,
.EmplID,
.FundCode,
.initial_approval_date,
.Total{
  @include flex-col-small;
}


.CognosServerID,
.CognosServerName,
.med,
.MainPI,
.PrimaryProjectPIName,
.ProjectPIName,
.AccountDescription,
.Description,
.CHARTSTRING,
.DeptartmentDescription,
.PrimaryFirstName,
.PrimaryLastName,
.VendorName,
.ReqName,
.ProjectID,
.org_category,
.grant_number,
.grant_office,
.Name,
.EmployeeHRDeptName,
.EmployeeJobTitle,
.EmployeeFirstName,
.EmployeeLastName,
.EmployeeName,
.Sponsor,
.ProjectID{
  @include flex-col-med;
}

.lg,
.FundDescription,
.AccountCategoryDescription,
.CampusEmail,
.org_name,
.ProjectDescription,
.Account,
.ProposalTitle{
  @include flex-col-large;
}

.ProjectName{
  flex-grow: 1;
  @include flex-col-exlarge;
}
