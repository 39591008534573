
tr.footer-row:first-child {
border-top: 1px solid #ccc;
}
tr.footer-row td{
  background : #eee;
  font-weight : bold;
}
.projection.disabled {
  opacity : 0.5;
}
.no-currency {
  &::before {
    content:'' !important;
    margin:0 !important;
  }
}
.edit-mode {
  input {
    width : 90%;
    padding:0;
    border:0;
  }
}
