
@import "./assets/_styles.scss";

.breadcrumbs {
  ul {
    padding-left : 0px;
    .crumb {
      float : left;
      list-style: none;
      padding-left : 0px;
      padding-right : 20px;
    }
  }
  clear:both
}
input{
  width: 100%;
  height: 30px;
  border: 1px solid $rich-black-20;
  &:focus{outline: none;}
  color: $nu-purple;
  font-family: $AkkuratProRegular;
  margin: 15px 0px;
  text-indent: 5px;
  text-overflow: ellipsis;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
