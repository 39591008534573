
.non-sponsored-projects {
  table {
    tbody {
      tr {
        td {cursor: pointer}
      }
    }
  }
}
