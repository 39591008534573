//Modules


//RD Button Dropdown Menu
.dropdown-menu {
  &.active {
    display : block;
  }
}

.rd-dropdown-menu{
  padding: 0;
  border: 1px solid $nu-purple-30;
  border-radius: 0;
  @include box-shadow;
  li{
    cursor: pointer;
    input{
      color: $nu-purple-120;
      font-family: $AkkuratProRegular;
      border: 0px;
      border-bottom: 1px solid $nu-purple-10;
      padding-right: 25px;
      padding-left: 10px;
      padding-bottom: 5px;
      margin-top: 5px;
      margin-bottom: 10px;
      &::-webkit-input-placeholder {
        color: $nu-purple-60;
      }

      &:-moz-placeholder { /* Firefox 18- */
        color: $nu-purple-60;
      }

      &::-moz-placeholder {  /* Firefox 19+ */
        color: $nu-purple-60;
      }

      &:-ms-input-placeholder {
        color: $nu-purple-60;
      }
      &:focus{
        outline: none;
        &::-webkit-input-placeholder {
          color: white;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: white;
        }
        &::-moz-placeholder {  /* Firefox 19+ */
          color: white;
        }
        &:-ms-input-placeholder {
          color: white;
        }
      }

    }
    i{
      position: absolute;
      right: 10px;
      top: 8px;
      color: $nu-purple-60;
    }
    a{
      padding-top: 4px;
      padding-bottom: 6px;
      font: 14px/1.8em $AkkuratProRegular;
    }
  }
  li:hover{
    a{
      background-color: $nu-purple-10;
    }
  }
  li.active{
    border-left: 5px solid $nu-purple;
    a{
      padding-left: 15px;
      color: $nu-purple;
      background-color: transparent;
    }
    &:hover{
      a{
        color: $nu-purple;
        background-color: $nu-purple-10;
      }
    }
  }
  input[type="search"]{
    width: 100%;
  }
}

//Checkbox
.rp-checkbox{
  input[type="checkbox"] {
    display:none;
  }
  input[type="checkbox"] + label span{
    display:inline-block;
    width:19px;
    height:19px;
    background-color: transparent;
    margin:-2px 8px 0 0;
    vertical-align:middle;
    border: 1px solid #b6acd1;
    cursor:pointer;
    background-image: url("#{$path-images}/RD_Icons_check.svg");
    @include transitionAll;
  }
  input[type="checkbox"]:checked + label span {
    background-color: $nu-purple;
    border-color: $nu-purple;
  }

  label{
    text-transform: uppercase;
    font-family: $AkkuratProRegular;
    font-size: 10px;
    color: $nu-purple;
    margin: 0px;
    line-height: 19px;
  }
  &:hover{
    input[type="checkbox"] + label span{
      background-color: $nu-purple-30;
      @include transitionAll;
    }
    input[type="checkbox"]:checked + label span {
      background-color: $nu-purple;
    }
    label{
      cursor:pointer;
    }
  }
}


//Datepicker and Periodpicker Module
.datepicker,
.periodpicker{
  position: relative;
  display: flex;
  align-items: center;
  float: right;
  font-family: $AkkuratProRegular;
  @include no-highlight;
  @include clearfix;
  //Month/Year Drawer
  ul.dp-drawer{
    position: absolute;
    width: 200px;
    margin-left: -50%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 40px;
    z-index: 99;
    list-style : none;
    padding: 0;
    border: 1px solid $nu-purple-10;
    background-color: white;
    @include box-shadow;
    @include clearfix;
    li{
      width: 33.333%;
      float: left;
      margin: 0;
      padding: 0;
      .grid{
        width: 100%;
        padding: 9px 0px;
        margin: 0;
        border: 0;
      }
    }
  }
  &.empty{
    .dp-btn{
      &:hover{
        border-color: $rich-black-10;
        background-color: white;
      }
    }
  }
}




// TODO: Replace this with RD Button code
//Datepicker Button
.dp-btn{
  display: inline-block;
  background-color: white;
  text-align: center;
  width: 65px;
  border: none;
  border-radius: 0;
  padding: 10px 0px;
  margin: 0px;
  color: $nu-purple;
  font-size: 16px;
  line-height: 16px;
  padding: 7px 0px;
  margin: 3px;
  border: 1px solid $nu-purple-10;
  min-height: 32px;
  @include transitionAll;
  &:focus{
    outline: 0;
  }
  &:hover{
    cursor: pointer;
    color: $nu-purple;
    background-color: $nu-purple-10;
    @include transitionAll;
  }
}
