
.usercard {
  margin-bottom : 0px;
  .usercard-info{
    padding-top : 0px;
    margin-right : 10px;
  }
  .user-position {
    float: left;
    font-size: 0.9em;
  }
  min-height: 40px;
  small a {
    cursor: pointer;
  }
}

.expando-enter-active, .expando-leave-active {
  transition: all 1s ease;
  max-height: 200px;
  overflow: hidden;
  opacity: 1;
}

.expando-enter {
  max-height: 0;
  opacity: 0;
}

