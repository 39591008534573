//Typography


h1{
  font: 26px/1.1em $CamptonBold;
  color: $nu-purple;
  margin-bottom: 5px;
  margin-top: 0;
}

h2 {
    font: 18px/40px $AkkuratProBold;
    text-transform: uppercase;
    color: #4e2a84;
    margin: 0px;
}

h3 {
    font: 18px $CamptonMedium;
    color: $nu-purple;
    line-height: 1.1em;
    margin: 0;
}

.small-gray-italic {
  font: 12px/1.2em $AkkuratLightItalic;
  font-weight : normal;
  color: $rich-black-20;
}


p{
  font: 16px/1.2em $AkkuratProRegular;
  color: $rich-black-80;
}

a{
  color: $nu-purple;
  &:hover{
    text-decoration: none;
    color: $nu-purple-30;
  }
}



.key{
  font-family: $AkkuratProBold;
  color: $rich-black-80;
  line-height: 1.2em;
}

.value{
  font-family: $AkkuratProRegular;
  color: $rich-black-80;
  line-height: 1.2em;
}




// web fonts
@font-face {
    font-family: "Akkurat Pro Light";
    src: url("#{$path-fonts}/AkkuratProLight.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Akkurat Pro Light Italic";
    src: url("#{$path-fonts}/AkkuratProLightItalic.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Akkurat Pro Regular";
    src: url("#{$path-fonts}/AkkuratProRegular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Akkurat Pro Italic";
    src: url("#{$path-fonts}/AkkuratProItalic.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Akkurat Pro Bold";
    src: url("#{$path-fonts}/AkkuratProBold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Akkurat Pro Bold Italic";
    src: url("#{$path-fonts}/AkkuratProBoldItalic.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Campton Bold";
    src: url("#{$path-fonts}/CamptonBold.woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Campton Bold Italic";
    src: url("#{$path-fonts}/CamptonBoldItalic.woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Campton Extra Bold";
    src: url("#{$path-fonts}/CamptonExtraBold.woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Campton Extra Bold Italic";
    src: url("#{$path-fonts}/CamptonExtraBoldItalic.woff");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: "Campton Light";
    src: url("#{$path-fonts}/CamptonLight.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Campton Light Italic";
    src: url("#{$path-fonts}/CamptonLightItalic.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Campton Medium";
    src: url("#{$path-fonts}/CamptonMedium.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Campton Medium Italic";
    src: url("#{$path-fonts}/CamptonMediumItalic.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Ionicons";
    src: url("#{$path-fonts}/ionicons.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
