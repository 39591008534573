header{
	font-family: $AkkuratProRegular;
	color: white;
	position: fixed;
	right: 0;
	left: 0;
	z-index: 1030;
	background: $nu-purple;
	top: 0;
	@media all and (max-width: 768px){
		position: relative;
	}
}


@media all and (min-width:769px){
	.mobile-only{
		display: none !important;
	}
}





nav.top-nav{
	background: $nu-purple-120;
	.container{
		@media all and (max-width: 768px){
			padding: 0;
		}
	}
	.branding{
		float: left;
		a{
			margin: 20px 5px;
			display: block;
			background-image: url("#{$path-images}/RP_Logo.png");
			background-size: 253px 20px;
			width: 253px;
			height: 20px;
			background-repeat: no-repeat;
			text-indent: -999em;
			overflow: hidden;
		}
		@media all and (max-width: 768px){
			width: 100%;
			padding: 0px 60px 0px 10px;
			background-color: $nu-purple-120;
		}
	}

	li{
		display: block;
		float: left;
		margin: 0px;
		padding: 0px;

		&:focus{
			background-color: transparent;
		}
		&.router-link-active{
			background-color: rgba($nu-purple-60, .5);
		}
		a.tab{
			@extend .rd-button;
			@extend .rd-button-icon-left;
			border-left: 1px solid rgba($nu-purple-60, .1);
			margin: 10px 0px;
			padding: 10px 35px;
			line-height: 20px;
			font-size: 12px;
			color: white;
			&.help{
				background-image: url(#{$path-images}/RD_Icons_help.svg);
			}
			&.policy{
				background-image: url(#{$path-images}/RD_Icons_policy.svg);
			}
			&:hover{
				opacity: .7;
				@include transition(opacity, .2s);
				cursor: pointer;
			}
		}

	}

	.user-menu{
		position: relative;
		float: right;

		.user-login-button{
			display: block;
			color: white;
			margin: 5px;
			line-height: 14px;
			font-size: 12px;
			padding: 7px;
			&:hover{
				opacity: .7;
				@include transition(opacity, .2s);
				cursor: pointer;
				background-color: $nu-purple-120;
			}
		}

		.usercard-wrapper-button{
			@include no-highlight;
			opacity: 1;
			@include transition(opacity, .2s);
			margin: 15px 0px;
			display: block;
			@include clearfix;
			.left{
				float: left;
				margin-right: 5px;
				.x-small{
					margin: 2px;
				}
			}
			.right{
				float: left;
				.name{
					color: white;
					margin: 0;
					line-height: 14px;
					font-size: 12px;
					padding: 7px;
				}
			}
			&:hover{
				opacity: .7;
				@include transition(opacity, .2s);
				cursor: pointer;
			}
		}

		.user-dropdown-menu{
			position: absolute;
			top: 45px; //Height of user-nav
			background-color: $ultralight-grey;
		  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
			right: 10px;
			left: -5%;
			z-index: 999;
			border-radius: 0;
			color: $nu-purple;
			margin: 0;
			.user-snippet{
				padding: 10px;
				background-color: white;
				@include clearfix;
				img{
					float: left;
					margin-right: 10px;
				}
				.user-name-email{
					margin-left: 4%;
					p{
						margin: 0px;
						color: $rich-black-80;
						font-family: $AkkuratProRegular;
					}
				}
			}
			.user-dropdown-menu-actions{
				padding: 10px;
				margin: 0;
				@include backdrop ($light-grey, $ultralight-grey);
				@include clearfix;
				li{
					display: block;
					float: left;
					margin: 0px;
					padding: 0px;
					p{
						padding: 10px 20px;
						color: white;
					}
					.actions{
						list-style-type: none;
						a{
							margin-bottom: 0;
							margin: 0% 1%;
							width: 47%;
						}
					}
				}
			}
		}
	}

	@media all and (max-width: 768px){
		display: none;
	}
}



nav.bottom-nav{
	.container{
		@media all and (max-width: 768px){
			padding: 0;
		}
	}

	.branding{
		float: left;
		a{
			margin: 20px 5px;
			display: block;
			background-image: url("#{$path-images}/RP_Logo.png");
			background-size: 253px 20px;
			width: 253px;
			height: 20px;
			background-repeat: no-repeat;
			text-indent: -999em;
			overflow: hidden;
		}
		@media all and (max-width: 768px){
			width: 100%;
			padding: 0px 60px 0px 10px;
			background-color: $nu-purple-120;
		}
	}

	.main-nav{
		ul.nav{
			list-style-type: none;
			float: right;
			@include clearfix;
			li{
				display: block;
				float: left;
				height: 60px;
				margin: 0px;
				padding: 0px;
				a{
					border-left: 1px solid rgba($nu-purple-60, .1);
					margin: 10px 0px;
					padding: 10px 20px;
					line-height: 20px;
					font-size: 12px;
					color: white;
					@include no-highlight;
					@include transition(background-color, .5s);
					&:hover{
						text-decoration: none;
						color: white;
						background-color: $nu-purple-60;
						@include transition(background-color, .5s);
					}
					&:focus{
						background-color: transparent;
					}
					&.router-link-active{
						background-color: rgba($nu-purple-60, .5);
					}
					&.tab{
						@extend .rd-button;
			      @extend .rd-button-icon-left;
						border-left: 1px solid rgba($nu-purple-60, .1);
						margin: 10px 0px;
						padding: 10px 35px;
						line-height: 20px;
						font-size: 12px;
						color: white;
						&.project{
							background-image: url(#{$path-images}/RD_Icons_lists.svg);
						}
						&.people{
							background-image: url(#{$path-images}/RD_Icons_peoplelisting.svg);
						}
						&.pi{
							background-image: url(#{$path-images}/RD_Icons_lists.svg);
						}
					}
				}
				&:first-of-type{
					a{
						border-left: none;
					}
				}
			}
		}
		@media all and (max-width: 768px){
			display: none;
		}
	}

	.mobile-menu-icon{
		background-color: transparent;
		position: absolute;
		display: block;
		top: 0px;
		right: 0px;
		border: 0;
		height: 60px;
		width: 60px;
		&:after{
			display: block;
			content: '\f20e';
			font-family: $Ionicons;
			position: relative;
			border: 0;
			font-size: 34px;
			line-height: 40px;
			background-color: transparent;
			right: 0px;
		}
		&.active{
			background-color: $nu-purple-60;
			&:after{
				content: '\f2d7';
			}
		}
	}

	.mobile-menu-dropdown{
		top: 60px;
		width: 100%;
		background-color: $nu-purple;
		position: absolute;
		ul.nav{
			li{
				button{
					@extend .rd-button;
					@extend .rd-button-purple;
					@include no-highlight;
					padding: 10px 15px;
					line-height: 20px;
					font-size: 14px;
					color: white;
					&:hover{
						background-color: transparent;
					}
				}
				a{
					text-transform: uppercase;
					color: white;
					&:hover{
						background-color: transparent;
					}
				}
				&:hover{
					background-color: $nu-purple-60;
				}
			}
		}
	}

}
