
.non-sponsored {
  th,
  td {text-align : right}
  td:first-child,
  th:first-child{text-align: left;}
}
.table-totals {
  table {
    tr {
      td {cursor : default}
    }
  }
}
