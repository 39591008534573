//Modals

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  position: relative;
  min-width: 300px;
  width: 80%;
  max-width: 1000px;
  margin: 0px auto;
  padding: 10px;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: $AkkuratProRegular;
  background-color: white;
  max-height: 500px;
}

.modal-header{
  padding: 0px 20px 5px 0px;
  border-bottom: 1px solid $light-grey;
  background-color: white;
  min-height: 46px;
  .modal-close{
    position: absolute;
    display: block;
    text-indent: -999em;
    overflow: hidden;
    top: 18px;
    right: 20px;
    width: 20px;
    height: 20px;
    background-image: url("#{$path-images}/RD_Icons_close.svg");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center;
    @include transitionAll;
    &:hover{
      opacity: .7;
      @include transitionAll;
    }
    &:active{
      background-size: 15px;
      @include transitionAll;
    }
  }
}

.modal-body {
  padding: 0px;
  .projections_list{
    list-style-type: none;
    padding-left: 0;
    max-height: 15em;
    min-height: 15em;
    overflow-y: scroll;
    .projection{
      margin: 10px 0px 0px 0px;
      .card{
        margin: 0;
      }
    }
  }
  &.feedback{
    padding: 10px 0px;
  }
  &.search{
    padding: 0px 0px 10px 0px;
  }
}

.modal-footer{
  .rd-submit-status{
    margin: 0px 10px;
  }
  border-top: none;
  padding: 0px;
  padding-top: 10px;
  border-top: 1px solid $light-grey;
  .prev{
    float: left;
  }
}

.modal-default-button {
  padding: 20px 0px;
}

.modal-funding-status{
  @include clearfix;
  padding: 20px;
  padding-top: 0;
  button.funding-status{
  }
  p.funding-status-metadata{
    @extend .small-gray-italic;
    margin: 0;
    line-height: 25px;
    float: right;
    @media all and (max-width: 768px){
      float: left;
      line-height: 1.5em;
    }
  }
}


// Download modal
.download-modal{
  .modal-body{
    padding: 15px 0px;
  }
  .modal-footer{
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
    button{
      margin-left: 10px !important;
    }
  }
}


/*
 * the following styles are auto-applied to elements with
 * v-transition="modal" when their visiblity is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter, .modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
