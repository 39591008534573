
footer {
  margin-top : 20px;
  margin-bottom : 20px;
}
.my-links{
  padding-left : 0;
  li {
    float : left;
    border-right : 1px solid red;
    padding-right : 10px;
    list-style : none;
    padding-left : 10px;
    margin-left : 0;
  }
  li:nth-child(1){
    padding-left : 0;
  }
  li:last-child{
    border-right : none !important;
  }
}
