
@mixin clearfix () {
  &:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}
.button_bar{
  position: relative;
  @include clearfix;
  margin: auto;
  width: 60%;
  display: flex;
  button{
    display: block;
    width: 33%;
    margin: 5px 5px;
  }
}
@media all and (max-width: 750px){
  .button_bar{
    @include clearfix;
    order: 1;
    clear: both;
    float: none;
    width: 100%;
    display: block;
    button{
      display: block;
      width: 100%;
      margin: 5px 0px;
    }
  }
}
