// Buttons

button{
  &:focus{
    outline: 0;
  }
}

.working{
  background-position: left bottom;
  @include transitionAll;
  &:after{
    opacity: 1;
    text-indent: -999em;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: $nu-purple-10;
    -webkit-animation: sk-rotate 1.5s infinite ease-in-out;
    animation: sk-rotate 1.5s infinite ease-in-out;
  }
}
.rd-button{
  font: 14px/14px $AkkuratProBold;
  border-radius: 0 !important;
  text-decoration: none;
  text-align: center;
  margin: 0;
  padding: 10px 16px;
  display: inline-block;
  text-transform: uppercase;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  cursor: pointer;
  min-width: 100px;
  @include no-highlight;
  @include transitionAll;
  &:hover{
    cursor: pointer;
    @include transitionAll;
  }
}

.rd-button-purple{
  color: white;
  background-color: $nu-purple;
  border: none;
  &:hover{
    color: white;
    background-color: $nu-purple-60;
  }
}

.rd-button-white{
  color: $nu-purple;
  background-color: white;
  border: 1px solid $nu-purple-30; //Maybe Cut?
  &:hover{
    color: $nu-purple;
    background-color: $nu-purple-30;
  }
  &.active{
    background-color: $nu-purple-10;
  }
  &.unavailable{
    opacity: .2;
    &:hover{
      background-color: transparent;
      cursor: not-allowed;
    }
  }
}

$bgW: 18px; //Size of Background Icon

.rd-button-icon-left{
  padding-left: $bgW*2;
  padding-right: $bgW/2;
  background-repeat: no-repeat;
  background-position: center left $bgW/2;
  background-size: $bgW;
  background-image: url(#{$path-images}/RD_Icons_swap.svg);
}

.rd-button-icon-right{
  padding-right: $bgW*2;
  padding-left: $bgW/2;
  background-repeat: no-repeat;
  background-position: center right $bgW/2;
  background-size: $bgW;
  background-image: url(#{$path-images}/RD_Icons_word.svg);
}

.rd-button-icon-top{
  font-family: $AkkuratProRegular;
  font-size: 10px;
  padding: 0px 5px 4px 5px;
  line-height: 11px;
  max-height: 39px;
  &:before{
    content: "";
    display: block;
    background-image: url(#{$path-images}/RD_Icons_swap.svg);
    position: relative;
    margin: auto;
    width: 23px;
    height: 23px;
    background-repeat: no-repeat;
    background-position: center center 4px;
    background-size: 23px;
    z-index: 10;
  }
}



//GM Style Button
.gm-button, input[type="submit"]  {
  @extend .rd-button;
  @extend .rd-button-purple;
}

//Add NetID Branding To Button
.netid_login{
  padding-left: 48px;
  padding-right: 16px;
  &:after{
    content: "";
    display: block;
    background-color: $nu-purple-120;
    background-image: url(#{$path-images}/RD_Icons_N.svg);
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
    z-index: 10;
    @include transitionAll;
  }
  &:hover{
    &:after{
      background-color: $nu-purple;
      @include transitionAll;
    }
  }
}

//RD Dropdown Toggle
.rd-dropdown-toggle{
  @extend .rd-button;
  @extend .rd-button-white;
  @extend .rd-button-icon-right;
  background-image: url(#{$path-images}/RD_Icons_swap.svg);
}
.rd-search{
  @extend .rd-button;
  @extend .rd-button-purple;
  @extend .rd-button-icon-left;

  padding: 10px 20px;
  padding-left: 25px;
  line-height: 20px;
  font-size: 12px;
  @include no-highlight;
  @include transition(background-color, .5s);
  background-size: 20px 20px;
  margin: 10px 10px;
  background-position: left;
  background-image: url(#{$path-images}/RD_Icons_search_white.svg);
}
//RD Download Buttons
.rd-download{
  @extend .rd-button;
  @extend .rd-button-white;
  @extend .rd-button-icon-left;
  background-size: 32px 64px;
  padding-left: 48px;
  padding-right: 16px;
  background-position: left bottom;
  border-color: $nu-purple;
  &.word{
    background-image: url(#{$path-images}/RD_Icons_word.svg);
  }
  &.excel{
    background-image: url(#{$path-images}/RD_Icons_excel.svg);
  }
  &.pdf{
    background-image: url(#{$path-images}/RD_Icons_pdf.svg);
  }
  &:hover{
    background-position: left top;
  }
  &.email{
    background-image: url(#{$path-images}/RD_Icons_email.svg);
  }
  &.working{
    background-position: left bottom;
    @include transitionAll;
    &:after{
      opacity: 1;
      content: ('.');
      text-indent: -999em;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      background-color: $nu-purple;
      -webkit-animation: sk-scaleout-x 1.5s infinite ease-in-out;
      animation: sk-scaleout-x 1.5s infinite ease-in-out;
    }
  }
}

//Submit Button Style
.rd-submit-button{
  @extend .rd-button;
  @extend .rd-button-purple;
  @extend .rd-button-icon-top;
  &:before{
    background-image: url(#{$path-images}/RD_Icons_check.svg);
  }
  &.submitted{
    background-color: $nu-purple-10;
  }
}

//Small Button Style
.rd-small-button{
  @extend .rd-button;
  @extend .rd-button-white;
  @extend .rd-button-icon-top;
  @media all and (min-width: 700px) and (max-width:950px){
    overflow: hidden;
    max-width: 50px;
    min-width: 0px;
    white-space: none;
    span{
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.tips{
    &:before{
      background-image: url(#{$path-images}/RD_Icons_support.svg);
    }
  }
  &.data_guide{
    &:before{
      background-image: url(#{$path-images}/RD_Icons_guide.svg);
    }
  }
  &.enhancement_log{
    &:before{
      background-image: url(#{$path-images}/RD_Icons_log.svg);
    }
  }
  &.feed_feedback{
    &:before{
      background-image: url(#{$path-images}/RD_Icons_feedback.svg);
    }
  }
  &.feed_close{
    &:before{
      background-image: url(#{$path-images}/RD_Icons_close.svg);
    }
  }
  &.search{
    &:before{
      background-image: url(#{$path-images}/RD_Icons_search.svg);
    }
  }
  &.inception{
    order: 3;
    margin-right: 5px;
    &:before{
      background-image: url(#{$path-images}/RD_Icons_inception.svg);
    }
  }
  &.month_to_month{
    order: 3;
    &:before{
      background-image: url(#{$path-images}/RD_Icons_month.svg);
    }
  }
  &.subcontracts-actual{
    order: 3;
    &:before{
      background-image: url(#{$path-images}/RD_Icons_Actual.svg);
    }
  }
  &.subcontracts-proposal{
    order: 3;
    &:before{
      background-image: url(#{$path-images}/RD_Icons_budget.svg);
    }
  }
  &.all-projects{
    order: 3;
    &:before{
      background-image: url(#{$path-images}/RD_Icons_all_projects.svg);
    }
  }
  &.pi-projects{
    order: 3;
    &:before{
      background-image: url(#{$path-images}/RD_Icons_pi_projects.svg);
    }
  }
  &.fyd-data{
    order: 3;
    &:before{
      background-image: url(#{$path-images}/RD_Icons_fy.svg);
    }
  }
  &.pfy-data{
    order: 3;
    &:before{
      background-image: url(#{$path-images}/RD_Icons_pfy.svg);
    }
  }
  &.proj_apply{
    &:before{
      background-image: url(#{$path-images}/RD_Icons_proj_apply.svg);
    }
  }
  &.proj_delete{
    &:before{
      background-image: url(#{$path-images}/RD_Icons_proj_delete.svg);
    }
  }
  &.proj_edit{
    &:before{
      background-image: url(#{$path-images}/RD_Icons_proj_edit.svg);
    }
  }
  &.proj_manage{
    &:before{
      background-image: url(#{$path-images}/RD_Icons_proj_manage.svg);
    }
  }
  &.proj_new{
    &:before{
      background-image: url(#{$path-images}/RD_Icons_proj_new.svg);
    }
  }
  &.proj_save{
    &:before{
      background-image: url(#{$path-images}/RD_Icons_proj_save.svg);
    }
  }
  &.user_profile{
    &:before{
      background-image: url(#{$path-images}/RD_Icons_myprofile.svg);
    }
  }
  &.user_logout{
    &:before{
      background-image: url(#{$path-images}/RD_Icons_logout.svg);
    }
  }
}

//Override Shrink
.feed_feedback{
  max-width: none;
}

.funding_status{
  @extend .rd-small-button;
  //Override Shrink
  max-width: none;
  &:before{
    margin-top: 2px;
    width: 18px;
    height: 18px;
    background-image: url(#{$path-images}/RD_Icons_markfordel.svg);
    background-size: 18px 36px;
    background-position: center bottom;
    border-radius: 100%;
    margin-bottom: 2px;
    @include transitionAll;
  }
  &:before{
    background-color: $nu-purple;
  }
  &:hover{
     background-color: lighten($red, 50%);
     color: $red;
     border-color: $red;
    &:before{
      background-position: center top;
      background-color: $red;
      @include transitionAll;
    }
  }
  &:active{
    color: $rich-black-50;
    background-color: $light-grey;
    border-color: $rich-black-50;
    &:before{
      background-color: $rich-black-50;
      background-position: center bottom;
    }
  }
  &.not_funded{
    color: $red;
    border-color: $red;
    &:before{
      background-color: $red;
      background-position: center top;
    }
    &:hover{
      background-color: $nu-purple-30;
      color: $nu-purple;
      border-color: $nu-purple;
      &:before{
        background-color: $nu-purple;
        background-position: center bottom;
      }
    }
    &:active{
      color: $rich-black-50;
      border-color: $rich-black-50;
      background-color: $light-grey;
      &:before{
        background-color: $rich-black-50;
        background-position: center bottom;
      }
    }
  }
  &.waiting{
    color: $rich-black-50;
    border-color: $rich-black-50;
    &:hover{
      cursor: progress;
      background-color: transparent;
    }
    &:before{
      -webkit-animation: sk-rotate 1.5s infinite linear;
      animation: sk-rotate 1.5s infinite linear;
      background-position: center bottom;
      background-color: $rich-black-50;
    }
  }
}


// RD Tabs
.nav-pills{
  display: inline-block;
  li{
    margin-bottom: 0;
    a.tab{
      @extend .rd-button;
      @extend .rd-button-white;
      @extend .rd-button-icon-left;
      margin: 0px;
      margin-right: 5px;
      &:last-of-type{
        margin: 0px;
      }

      &.finoverview{
          background-image: url(#{$path-images}/RD_Icons_overview.svg);
      }
      &.charts{
          background-image: url(#{$path-images}/RD_Icons_charts.svg);
      }
      &.costshare{
          background-image: url(#{$path-images}/RD_Icons_costshare.svg);
      }
      &.payments{
          background-image: url(#{$path-images}/RD_Icons_payment.svg);
      }
      &.subcontractor{
          background-image: url(#{$path-images}/RD_Icons_subcontract.svg);
      }
      &.protocol{
          background-image: url(#{$path-images}/RD_Icons_protocols.svg);
      }
      &.sponsored{
          background-image: url(#{$path-images}/RD_Icons_sponsored.svg);
      }
      &.nonsponsored{
          background-image: url(#{$path-images}/RD_Icons_nonsponsored.svg);
      }
      &.pending{
          background-image: url(#{$path-images}/RD_Icons_pending.svg);
      }
      &.people{
        background-image: url(#{$path-images}/RD_Icons_peopletab.svg);
      }
    }
  }
  li.router-link-active{
  	font-weight: normal;
    a.tab{
      background-color: $nu-purple-10;
    }
  }
}



//RD Load More Button
.rd-load-more{
  @include no-highlight;
  padding: 7px;
  position: relative;
  border: 0;
  margin-top: 10px;
  background-color: $light-grey;
  font: 12px/1.2em $AkkuratProBold;
  text-transform: uppercase;
  color: $nu-purple;
  color: $rich-black-50;
  width: 100%;
  display: block;
  text-align: center;
  @include transitionAll;
  &:hover,
  &:focus{
    cursor: pointer;
    color: $nu-purple;
    background-color: $nu-purple-10;
    @include transitionAll;
  }
}

// //RD Important Directional Buttons
.arr{
  font: 12px $AkkuratLight;
  line-height: 14px;
  color: $white;
  background: $nu-purple;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;
  padding: 11px 0px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  display: inline-block;
  vertical-align: middle;
  position: relative;
  @include transitionAll;
  min-width: 50px;
  border: none;
  opacity: 1;
  &:hover{
    opacity: .6;
    color: white;
    text-decoration: none;
    @include transitionAll;
  }
  &.forward{
    margin:0px;
    margin-right: 18px;
    margin-left: 10px;
    padding-left: 5px;
    &:after{
      content:"";
      position: absolute;
      left: 100%;
      top:0px;
      width:0px;
      height:0px;
      border-top:18px solid transparent;
      border-left:18px solid $nu-purple;
      border-bottom:18px solid transparent;
    }
  }
  &.back{
    margin: 0px;
    margin-left: 18px;
    margin-right: 10px;
    padding-right: 5px;
    &:after{
      content:"";
      position: absolute;
      right: 100%;
      top:0px;
      width:0px;
      height:0px;
      border-top:18px solid transparent;
      border-right:18px solid $nu-purple;
      border-bottom:18px solid transparent;
    }
  }
}

//RD Subtle Directional Buttons
.dir-btn{
  @extend .rd-button;
  background-color: transparent;
  padding: 10px 0px;
  border: none;
  color: $nu-purple;
  @include transitionAll;
  &:before{
    display: block;
    position: absolute;
    font-size: 34px;
    font-family: $Ionicons;
    color: $nu-purple;
    @include transitionAll;
  }
  &.prev{
    padding-left: 25px;
    margin-right: 5px;
    &:before{
      left: 5px;
      content: "\f3cf";
    }
  }
  &.next{
    padding-right: 25px;
    margin-left: 5px;
    &:before{
      right: 5px;
      content: "\f3d1";
    }
  }
  &:focus{
    outline: 0;
  }
  &:hover{
    cursor: pointer;
    @include transitionAll;
    color: $nu-purple-10;
    &:before{
      color: $nu-purple-10;
    }
    &.prev{
      &:before{
        left: 3px;
      }
    }
    &.next{
      &:before{
        right: 3px;
      }
    }
  }
  &.disabled{
    color: $rich-black-10;
    &:hover{
      color: $rich-black-10;
    }
    &:before{
      display: none;
    }
  }
}

// Return To Top
.scrolltotop {
  position: fixed;
  right: 35px !important;
  left: auto !important;
  z-index: 99;
  -webkit-transition: bottom .5s ease;
  -moz-transition: bottom  .5s ease;
  transition: bottom .5s ease;
  a{
    display: block;
    position: relative;
    background-color: $nu-purple-120;
    border-radius: 100px;
    overflow: hidden;
    text-indent: -999em;
    height: 50px;
    width: 50px;
    right: 10px;
    z-index: 100;
    border: none;
    border-bottom: none;
    background-image: url('#{$path-images}/arrow-down-white.svg');
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: center 60%;
    -webkit-transform:scaleY(-1);
    -moz-transform:scaleY(-1);
    -ms-transform:scaleY(-1);
    -o-transform:scaleY(-1);
    transform:scaleY(-1);
    @include transitionAll;
    &:hover{
      background-color: $nu-purple-60;
      cursor: pointer;
      @include transitionAll;
      @include box-shadow;
      a{
        text-decoration: none;
      }
    }
  }
}
