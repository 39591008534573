@import "elements/variables";
@import "elements/typography";
@import "elements/mixins";
@import "elements/modules";
@import "elements/buttons";
@import "elements/modals";
@import "elements/layout";
@import "elements/header";
@import "elements/footer";
@import "elements/tables";
@import "elements/skrollr";

.user-profile-picture {
	border-radius : 50%;
	width : 50px;
	height : auto;
	&.x-small{
		width: 24px;
	}
	&.medium{
		width: 48px;
	}
	&.large{
		width: 60px;
	}
}

.dev_wrapper{
	background-color: white;
	border: 1px solid $light-grey;
	padding: 0px 10px;
	border-bottom: 2px solid $light-grey;
	pre{
		margin-top: 5px;
	}
}
.home-notification{
	position: relative;
	width: 100%;
	max-width: 1600px;
	top: 20px;
	margin: auto;
  padding-top: 30px;
	// padding: 0px 15px 0px 15px;
	// @media all and (min-width: 700px){
	// 	padding: 0px 15px;
	// }
}
#announcements{
	position: static;
	max-width: 1600px;
	margin: auto;
	padding: 0px 1px;
	@media all and (min-width: 700px){
		padding: 15px 15px;
	}
}

.wp-warning{
	background-color: $nu-purple-10;
	font-family: $AkkuratProBold;
	color: $nu-purple-120;
	padding: 10px;
	padding-left: 50px;
	margin: 10px auto;
	border: 1px solid $nu-purple;
	background-image: url("#{$path-images}/RD_Icons_alert.svg");
	background-position: left center;
	background-size: 40px 40px;
	background-repeat: no-repeat;
}


.wp-notification {
	background-color: $nu-purple-10;
	font-family: $AkkuratProBold;
	color: $nu-purple-120;
	padding: 10px;
	padding-left: 70px;
	margin: 10px auto;
	border: 1px solid $nu-purple;
	background-image: url("#{$path-images}/RD_Icons_feedback.svg");
	background-position: left 10px center;
	background-size: 40px 40px;
	background-repeat: no-repeat;
	ul{
		padding-left: 17px;
	}
}


.contain-970{
	margin: 0 auto;
	max-width: 970px;
	box-sizing: content-box;
}
