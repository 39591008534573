

.feedback-modal {
  input {
    width : 100%;
  }
}
.feedback-button-wrapper {
  opacity: .9;
  z-index: 1000;
  position: fixed;
  bottom : -1px;
  right : -1px;
  button{
    border-color: #401f68;
  }
}
