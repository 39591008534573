#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 100px;
  background-color: $rich-black-80;
  .branding{
	  float: left;
	  a{
		  margin: 25px 5px;
		  display: block;
		  background-image: url("#{$path-images}/NU_Wordmark.png");
		  background-size: 160px 50px;
		  width: 160px;
		  height: 50px;
		  background-repeat: no-repeat;
		  text-indent: -999em;
		  overflow: hidden;
	  }
  }
  .my-links{
	  margin: 0;
	  float: right;
	  li{
      border-right: 1px solid rgba(white, .1);
		  margin: 30px 0px;
		  padding: 10px 10px;
      @include no-highlight;
      @include transition(background-color, .5s);
      a{
			  font-family: $AkkuratProRegular;
			  color: white;
			  line-height: 20px;
		  }
      &:hover{
        background-color: $rich-black-20;
        @include transition(background-color, .5s);
      }
	  }
  }

  @media all and (min-width: 769px){
    height: auto;
    .branding{
      a{
        margin-top: 35px;
        width: 80px;
        height: 25px;
        background-size: 80px 25px;
      }
    }
        .my-links{
      li{
        padding: 5px;
        a{
          width: 100%;
          font-size: 12px;
        }
      }
    }
  }

  @media all and (max-width: 768px){
    height: auto;
    .branding{
      float: none;
    }
    .my-links{
      width: 100%;
      float: left;
      li{
        margin: 10px 0px;
        border: none;
        float: none;
      }
    }
  }
}

